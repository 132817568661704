import React, { createContext, useContext, useMemo, useState } from 'react';

const initialData = {
  churnedPopup: { isOpen: false, couponCode: null, source: null },
  noSubscriptionPopup: false,
  pendingAccessPopup: false,
  completeRegistrationPopup: false,
  archiveDialog: false,
  upgradeToCastingToolPopUp: false,
  upgradeSubscriptionPopUp: { isOpen: false, productId: null },
  reActivatePopUp: { isOpen: false, type: null },
  switchProfilePopUp: false,
  subscriptionNudgesPopup: { isOpen: false, couponCode: null, source: null, popupType: null, isCompare: true },
  adminPopUp: { isOpen: false },
};

export const NotificationPopupContext = createContext(initialData);

const NotificationPopupProvider = props => {
  const [churnedPopup, setChurnedPopup] = useState(initialData.churnedPopup);
  const [archiveDialog, setArchiveDialog] = useState(initialData.archiveDialog);
  const [noSubscriptionPopup, setNoSubscriptionPopup] = useState(initialData.noSubscriptionPopup);
  const [pendingAccessPopup, setPendingAccessPopup] = useState(initialData.pendingAccessPopup);
  const [completeRegistrationPopup, setCompleteRegistrationPopup] = useState(initialData.pendingAccessPopup);
  const [upgradeToCastingToolPopup, setUpgradeToCastingToolPopup] = useState(initialData.upgradeToCastingToolPopUp);
  const [upgradeSubscriptionPopUp, setUpgradeSubscriptionPopUp] = useState(initialData.upgradeSubscriptionPopUp);
  const [switchProfilePopUp, setSwitchProfilePopUp] = useState(initialData.switchProfilePopUp);
  const [reActivatePopUp, setReactivatePopUp] = useState(initialData.reActivatePopUp);
  const [subscriptionNudgesPopup, setSubscriptionNudgesPopup] = useState(initialData.subscriptionNudgesPopup);
  const [adminPopUp, setAdminPopUp] = useState(initialData.adminPopUp);

  const dataValue = useMemo(
    () => ({
      churnedPopup,
      setChurnedPopup,
      archiveDialog,
      setArchiveDialog,
      noSubscriptionPopup,
      setNoSubscriptionPopup,
      pendingAccessPopup,
      setPendingAccessPopup,
      completeRegistrationPopup,
      setCompleteRegistrationPopup,
      upgradeToCastingToolPopup,
      setUpgradeToCastingToolPopup,
      upgradeSubscriptionPopUp,
      setUpgradeSubscriptionPopUp,
      reActivatePopUp,
      setReactivatePopUp,
      switchProfilePopUp,
      setSwitchProfilePopUp,
      subscriptionNudgesPopup,
      setSubscriptionNudgesPopup,
      adminPopUp,
      setAdminPopUp,
    }),
    [
      churnedPopup,
      archiveDialog,
      noSubscriptionPopup,
      pendingAccessPopup,
      completeRegistrationPopup,
      upgradeToCastingToolPopup,
      upgradeSubscriptionPopUp,
      reActivatePopUp,
      switchProfilePopUp,
      subscriptionNudgesPopup,
      adminPopUp,
    ],
  );

  return <NotificationPopupContext.Provider value={dataValue} {...props} />;
};

export const useNotificationPopup = () => useContext(NotificationPopupContext);

export default NotificationPopupProvider;
