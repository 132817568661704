import { useMemo, useEffect } from 'react';
import markerSDK from '@marker.io/browser';
import { useRouter } from 'next/router';

import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import { bugsnagNotify } from 'utils/bugsnagClientWrapper';
import { useDialogs } from 'utils/hooks/useDialogs';
import useAppContext from 'utils/hooks/useAppContext';

import { route } from 'constants/routes';

export default function useMarkerIO(enable = false) {
  const user = useUserData();
  const { visitorId } = useAppContext();
  const router = useRouter();
  const {
    isArtistNoAccessModalOpen,
    isContactFormModalOpen,
    isAlreadyClaimedModal,
    isEditDisclaimerModal,
    isProfileAccessRequestsModalOpen,
    isRequestAccessModalOpen,
    isSwitchProfileModalOpen,
    isOnboardingModal,
    isOpenActionCenter,
    isLoginDialog,
  } = useDialogs();
  const hasRegistrationRoute = route.REGISTER === router.route;
  const hasDialogsOpen = useMemo(
    () =>
      isArtistNoAccessModalOpen ||
      isContactFormModalOpen ||
      isAlreadyClaimedModal ||
      isEditDisclaimerModal ||
      isProfileAccessRequestsModalOpen ||
      isRequestAccessModalOpen ||
      isSwitchProfileModalOpen ||
      isOnboardingModal ||
      isOpenActionCenter ||
      isLoginDialog?.isOpen,
    [
      isAlreadyClaimedModal,
      isArtistNoAccessModalOpen,
      isContactFormModalOpen,
      isEditDisclaimerModal,
      isOnboardingModal,
      isOpenActionCenter,
      isProfileAccessRequestsModalOpen,
      isRequestAccessModalOpen,
      isSwitchProfileModalOpen,
      isLoginDialog,
    ],
  );

  return useEffect(() => {
    if (enable && process.env.MARKER_IO_DESTINATION_ID && !hasRegistrationRoute && !hasDialogsOpen) {
      const widgetParams = {
        destination: process.env.MARKER_IO_DESTINATION_ID,
        customData: {
          buildNumber: process.env.BUILD_NUMBER,
          visitorId,
        },
      };
      if (user) {
        // Provide user data to the widget
        const { email, name: fullName } = user;
        widgetParams.reporter = {
          email,
          fullName,
        };
      }

      try {
        markerSDK.loadWidget(widgetParams);
      } catch (e) {
        console.error('useMarkerIO:', e);
      }
    }
    // Hide widget on unmount
    return () => {
      if (window.Marker) {
        try {
          window.Marker.unload();
        } catch (e) {
          bugsnagNotify(e, { enable });
        }
      }
    };
  }, [enable, user, hasDialogsOpen]);
}
